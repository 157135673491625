import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/roboto/300.css";

//const React = require("react");
export const onRouteUpdate = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const source = params.get("utm_source")?.trim() || "";
  if (source.length > 0) localStorage.setItem("utm_source", source);
};
