exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-our-team-js": () => import("./../../../src/pages/about-us/our-team.js" /* webpackChunkName: "component---src-pages-about-us-our-team-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-determine-your-health-class-js": () => import("./../../../src/pages/determine-your-health-class.js" /* webpackChunkName: "component---src-pages-determine-your-health-class-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-he-about-us-index-js": () => import("./../../../src/pages/he/about-us/index.js" /* webpackChunkName: "component---src-pages-he-about-us-index-js" */),
  "component---src-pages-he-about-us-our-team-js": () => import("./../../../src/pages/he/about-us/our-team.js" /* webpackChunkName: "component---src-pages-he-about-us-our-team-js" */),
  "component---src-pages-he-blog-index-js": () => import("./../../../src/pages/he/blog/index.js" /* webpackChunkName: "component---src-pages-he-blog-index-js" */),
  "component---src-pages-he-blog-mdx-slug-js": () => import("./../../../src/pages/he/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-he-blog-mdx-slug-js" */),
  "component---src-pages-he-contact-js": () => import("./../../../src/pages/he/contact.js" /* webpackChunkName: "component---src-pages-he-contact-js" */),
  "component---src-pages-he-determine-your-health-class-js": () => import("./../../../src/pages/he/determine-your-health-class.js" /* webpackChunkName: "component---src-pages-he-determine-your-health-class-js" */),
  "component---src-pages-he-get-a-quote-js": () => import("./../../../src/pages/he/get-a-quote.js" /* webpackChunkName: "component---src-pages-he-get-a-quote-js" */),
  "component---src-pages-he-index-js": () => import("./../../../src/pages/he/index.js" /* webpackChunkName: "component---src-pages-he-index-js" */),
  "component---src-pages-he-learn-types-of-life-insurance-js": () => import("./../../../src/pages/he/learn/types-of-life-insurance.js" /* webpackChunkName: "component---src-pages-he-learn-types-of-life-insurance-js" */),
  "component---src-pages-he-learn-what-is-life-insurance-js": () => import("./../../../src/pages/he/learn/what-is-life-insurance.js" /* webpackChunkName: "component---src-pages-he-learn-what-is-life-insurance-js" */),
  "component---src-pages-he-learn-who-needs-life-insurance-js": () => import("./../../../src/pages/he/learn/who-needs-life-insurance.js" /* webpackChunkName: "component---src-pages-he-learn-who-needs-life-insurance-js" */),
  "component---src-pages-he-learn-why-buy-us-life-insurance-js": () => import("./../../../src/pages/he/learn/why-buy-us-life-insurance.js" /* webpackChunkName: "component---src-pages-he-learn-why-buy-us-life-insurance-js" */),
  "component---src-pages-he-testimonials-js": () => import("./../../../src/pages/he/testimonials.js" /* webpackChunkName: "component---src-pages-he-testimonials-js" */),
  "component---src-pages-he-the-process-js": () => import("./../../../src/pages/he/the-process.js" /* webpackChunkName: "component---src-pages-he-the-process-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-types-of-life-insurance-js": () => import("./../../../src/pages/learn/types-of-life-insurance.js" /* webpackChunkName: "component---src-pages-learn-types-of-life-insurance-js" */),
  "component---src-pages-learn-what-is-life-insurance-js": () => import("./../../../src/pages/learn/what-is-life-insurance.js" /* webpackChunkName: "component---src-pages-learn-what-is-life-insurance-js" */),
  "component---src-pages-learn-who-needs-life-insurance-js": () => import("./../../../src/pages/learn/who-needs-life-insurance.js" /* webpackChunkName: "component---src-pages-learn-who-needs-life-insurance-js" */),
  "component---src-pages-learn-why-buy-us-life-insurance-js": () => import("./../../../src/pages/learn/why-buy-us-life-insurance.js" /* webpackChunkName: "component---src-pages-learn-why-buy-us-life-insurance-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-the-process-js": () => import("./../../../src/pages/the-process.js" /* webpackChunkName: "component---src-pages-the-process-js" */)
}

