import * as React from "react";
import Button from "src/components/button.js";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export default class ContactForm extends React.Component {
  state = {
    fullName: "",
    email: "",
    phone: "",
    message: "",
    sending: false,
    sent: false,
    error: false,
  };

  recaptchaInstance = React.createRef();

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  validateForm = () => {
    //.match(/^[^@.].*\@.+\..+$/)
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ sending: true, error: false });
    const token = await this.recaptchaInstance.current.executeAsync();

    console.log();

    const response = await fetch("https://api.israel.spetner.com/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fullname: this.state.fullName,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        suffix: process.env.GATSBY_SUFFIX,
        source: localStorage.getItem("utm_source") || "",
        token,
      }),
    });
    const result = await response.json();
    this.setState({ sending: false });
    if (result.status === "SUCCESS") {
      this.setState({ sent: true });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    const { copy } = this.props;

    return (
      <div>
        {this.state.sent ? (
          <div className="col-span-2 text-blue-light mt-10 w-2/3 mx-auto">
            {copy.success}
          </div>
        ) : (
          <form
            onSubmit={this.handleSubmit}
            className="grid grid-cols-2 gap-2 m-10"
          >
            <input
              name="fullName"
              required
              value={this.state.fullName}
              onChange={this.handleInputChange}
              className="col-span-2 lg:col-span-1 border border-gray-300 rounded py-1 px-2 text-sm
              focus:outline-none focus:shadow-sm focus:shadow-inner"
              placeholder={copy.fields.name}
              disabled={this.state.sending}
            />
            <input
              type="tel"
              name="phone"
              value={this.state.phone}
              onChange={this.handleInputChange}
              className="col-span-2 lg:col-span-1 border border-gray-300 rounded py-1 px-2 text-sm
              focus:outline-none focus:shadow-sm focus:shadow-inner"
              placeholder={copy.fields.phone}
              disabled={this.state.sending}
            />
            <input
              type="email"
              name="email"
              required
              value={this.state.email}
              onChange={this.handleInputChange}
              className="col-span-2 border border-gray-300 rounded py-1 px-2 text-sm
              focus:outline-none focus:shadow focus:shadow-inner"
              placeholder={copy.fields.email}
              disabled={this.state.sending}
            />
            <textarea
              name="message"
              required
              value={this.state.message}
              onChange={this.handleInputChange}
              cols="30"
              rows="10"
              className="col-span-2 border border-gray-300 rounded py-1 px-2 text-sm
              focus:outline-none focus:shadow-sm focus:shadow-inner"
              placeholder={copy.fields.message}
              disabled={this.state.sending}
            ></textarea>

            {this.state.error && (
              <div className="col-span-2 text-red-700">{copy.error}</div>
            )}

            <div className="col-span-2">
              {this.state.sending ? (
                <FontAwesomeIcon
                  className="animate-spin mx-auto mt-2 text-blue-light text-xl"
                  icon={faCircleNotch}
                />
              ) : (
                <Button
                  submit
                  invert
                  arrow
                  className="mx-auto py-2 mt-2 border-none hover:border-none shadow-lg"
                >
                  {copy.button}
                </Button>
              )}
            </div>

            <ReCAPTCHA
              size="invisible"
              sitekey="6LfJjfQbAAAAAByOo1Ky6GwKmhKTqwKSGUVAJvd5"
              onChange={this.verifyCallback}
              ref={this.recaptchaInstance}
              badge="inline"
              className="col-span-2 mx-auto mt-4"
            />
          </form>
        )}
      </div>
    );
  }
}
