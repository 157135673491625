import * as React from "react";
import ArrowWhite from "src/images/arrow-white.svg";
import ArrowBlue from "src/images/arrow-blue.svg";
import { Link } from "gatsby";

const Button = (props) => {
  const colors = props.invert
    ? "hover:text-white hover:bg-blue bg-white text-blue"
    : "text-white bg-blue-light border-blue-light hover:bg-white hover:text-blue";
  const arrow = props.arrow ? (
    <span>
      <img
        alt=""
        src={props.invert ? ArrowBlue : ArrowWhite}
        className={`group-hover:hidden button-arrow ${
          props.reverse ? "reverse" : ""
        }`}
      />
      <img
        alt=""
        src={props.invert ? ArrowWhite : ArrowBlue}
        className={`hidden group-hover:block button-arrow  ${
          props.reverse ? "reverse" : ""
        }`}
      />
    </span>
  ) : (
    ""
  );

  let leftArrow, rightArrow;
  if (props.reverse) leftArrow = arrow;
  else rightArrow = arrow;

  return props.href ? (
    <Link
      to={props.href}
      className={`group flex items-center gap-1 btn-quote transition duration-500 ease-in-out py-3 px-6 rounded-full border border-blue ${colors} ${
        props.className || ""
      }`}
    >
      {leftArrow}
      <span>{props.children}</span>
      {rightArrow}
    </Link>
  ) : (
    <button
      type={props.submit ? "submit" : false}
      className={`group flex items-center gap-1 btn-quote transition duration-500 ease-in-out py-3 px-6 rounded-full border border-blue ${colors} ${
        props.className || ""
      }`}
    >
      {leftArrow}
      <span>{props.children}</span>
      {rightArrow}
    </button>
  );
};

export default Button;
